import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="アクセシビリティ - 原則" mdxType="SEO" />
    <PageTitle title="アクセシビリティ" enTitle="Accessibility" mdxType="PageTitle" />
    <h2 {...{
      "id": "誰もがいつでも迷わず書く読む応えるができる状態を目指す",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%AA%B0%E3%82%82%E3%81%8C%E3%81%84%E3%81%A4%E3%81%A7%E3%82%82%E8%BF%B7%E3%82%8F%E3%81%9A%E6%9B%B8%E3%81%8F%E8%AA%AD%E3%82%80%E5%BF%9C%E3%81%88%E3%82%8B%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E7%8A%B6%E6%85%8B%E3%82%92%E7%9B%AE%E6%8C%87%E3%81%99",
        "aria-label": "誰もがいつでも迷わず書く読む応えるができる状態を目指す permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`誰もがいつでも、迷わず「書く」「読む」「応える」ができる状態を目指す`}</h2>
    <p>{`Amebaはこれまでもユーザーの生活に寄り添ってきました。`}<br />{`
「人と情報をつむぎ`}{` `}{`暮らしが豊かに育ちつづけるための機会をつくる。」をミッションに掲げ、`}<strong parentName="p">{`ユーザーの「変化」にも寄り添って`}</strong>{`いきます。`}<br />{`
今利用しているユーザーが歳をとっても、怪我や眼鏡を忘れてしまったときにも、ユーザーが日々遭遇するあらゆる`}<strong parentName="p">{`環境や状況の変化が障害になるデザイン`}</strong>{`であってはいけません。`}</p>
    <p>{`アクセシビリティを高めると、障害者や高齢者、子どもも含めた`}<strong parentName="p">{`誰もが使える`}</strong>{`ようになります。`}<br />{`
そしてそれは、`}<strong parentName="p">{`いつでも使いやすい`}</strong>{`につながります。`}</p>
    <p>{`Amebaはミッションの追求のために「誰もがいつでも、迷わずコンテンツを楽しむことができる」サービスを目指し、これからもアクセシビリティの向上に取り組みつづけていきます。`}</p>
    <h2 {...{
      "id": "多様な能力利用方法に対する選択肢",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A4%9A%E6%A7%98%E3%81%AA%E8%83%BD%E5%8A%9B%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E9%81%B8%E6%8A%9E%E8%82%A2",
        "aria-label": "多様な能力利用方法に対する選択肢 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`多様な能力、利用方法に対する選択肢`}</h2>
    <p>{`いつも使っているユーザーでも、`}<strong parentName="p">{`アクセスするタイミングや場所によっては使いづらさを抱えています。`}</strong>{`障害者や高齢者、子どもは、`}<strong parentName="p">{`いつも使いづらい`}</strong>{`と感じているかもしれません。それぞれの特性を理解し、使い方を知ることで、アクセシビリティを高めましょう。`}</p>
    <p>{`先の例を見て「全ての人が使いやすいものを作るのは無理だ」と感じてしまうかもしれません。`}</p>
    <p>{`しかし、たとえば「視線・舌などの動きやスイッチで操作するデバイス」は、実はクリックで操作ができる画面を動かせるように作られています。「マウスでホバーした時に表示される吹き出し」は、キー操作では見ることができません。しかし「フォーカスしたとき`}<em parentName="p">{`にも`}</em>{`吹き出しが表示」されれば、キー操作でも見ることができます。「別の手段」を考えることで、`}<strong parentName="p">{`アクセスできる人は確実に増える`}</strong>{`のです。`}</p>
    <p>{`重要なのは、あらゆる状況に備えられるよう、`}<strong parentName="p">{`わかりやすく柔軟でミスに強い`}</strong>{`こと、そして`}<strong parentName="p">{`自分以外の使い方を想像する`}</strong>{`ことです。`}</p>
    <p>{`Spindleでデザインされている原則やコンポーネントなどは基本的にアクセシブルに作られていますが、それでも解決できない場合は複数の選択肢を用意しましょう。`}</p>
    <h2 {...{
      "id": "ameba-accessibility-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ameba-accessibility-guidelines",
        "aria-label": "ameba accessibility guidelines permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Ameba Accessibility Guidelines`}</h2>
    <p>{`Amebaでは、`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`WCAG2.1`}</a>{`の内容を簡略化し、Amebaのサービスでよくある事例を追加したガイドラインを独自で作成しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://a11y-guidelines.ameba.design/"
      }}>{`Ameba Accessibility Guidelines`}</a></p>
    <p>{`Spindleにおけるアクセシビリティの考え方もAmeba Accessibility Guidelinesを踏襲したものです。アクセシビリティに関する詳細な解説や事例もあります。もしも疑問に思うことがあれば、Ameba Accessibility Team（Slack: #ameba_accessibility）までご相談ください。`}</p>
    <ContentsList list={[{
      url: "/principles/accessibility/examples/",
      title: "使いづらさと使い方の例",
      enTitle: "Examples",
      summary: "いくつかの使いづらい例を掲載しています。"
    }, {
      url: "/principles/accessibility/resources/",
      title: "関連資料",
      enTitle: "Resources",
      summary: "アクセシビリティに関する関連資料を掲載しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      